import Headroom from "../components/headroom.min";
const elem = document.querySelector("header");
const headroom = new Headroom(elem, {
  "offset": 120,
  "tolerance": 6,
  "classes": {
    "initial": "animated",
    "pinned": "slideDown",
    "unpinned": "slideUp"
  }
});
headroom.init();

$(function() {


  $("#btnNav").on("click touchstart", function(event) {
    event.preventDefault();
    const btn = $("#btnNav")
    let expanded = btn.attr("aria-expanded");

    if (expanded === "false") {
      btn.attr("aria-expanded", true);
    } else {
      btn.attr("aria-expanded", false);
    }

    if ($(this).attr("aria-expanded") === "false") {
      btn.find('span').text("menu");
      btn.find('i').removeClass().addClass('icon-bars');
    } else {
      btn.find('span').text("sluiten");
      btn.find('i').removeClass().addClass('icon-times');
    }
    $(".header_wrap .nav_wrap").slideToggle();
  });

  if ($("#ajax-load-more").length) {
    window.almDone = function(){
      if($("#ajax-load-more").attr('data-total-posts') == 0) {
        $('.alm-no-results').fadeIn();
      } else {
        $('.alm-no-results').fadeOut();
      }
    };
  }
});