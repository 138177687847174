$(function() {
  if ($(".review_carousel").length) {
    $(".review_carousel").owlCarousel({
      autoHeight: true,
      autoplay: 12500,
      dots: true,
      items: 1,
      lazyLoad: true,
      loop: true,
      nav: false,
    });
  }

  if ($(".project_media").length) {
    $(".project_media").owlCarousel({
      autoHeight: true,
      dots: false,
      lazyLoad: true,
      margin: 12,
      nav: true,
      navText: ["", ""],
      responsive: {
        0: {
          items: 2
        },
        590: {
          items: 3
        },
        768: {
          items: 4,
          margin: 24
        },
        992: {
          items: 6
        }
      }
    });
  }
});